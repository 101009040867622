import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import cn from './languages/cn'
import en from './languages/en'

Vue.use(VueI18n)

const messages = {
  en: {
    ...en,
    ...elementEnLocale
  },
  cn: {
    ...cn,
    ...elementZhLocale
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'en',
  messages: messages
})

export default i18n
