/**
 * @namespace 常用工具合集
 * @version 1.0.0
 * @author one
 * @copyright Liu, Wanheng 2019-2022
 * @license MIT
 */

import request from '@/services/request'
import moment from 'moment'
import Vue from 'vue'
import { getReceivingVesselListBySearch } from '@/services/modules/comm.js'
const toggleFullScreen = () => {
  const isFullscreen = document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement
  const element = document.documentElement
  if (isFullscreen) {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  } else {
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }
  }
}

/**
 * 按照首字母排序
 * @param {Array} list:需要排序的目标对象
 * @param {String} field:需要排序的字段
 */
const sortByAlphabetical = (list, field) => {
  const newList = JSON.parse(JSON.stringify(list))
  return newList.sort((a, b) => {
    const a1 = a[field] ?? 'z'
    const b1 = b[field] ?? 'z'
    return a1.charCodeAt(0) - b1.charCodeAt(0)
  })
}
/**
 * 检查无效字符串
 */
const isValidString = (str) => {
  let isNoValid = true
  if (!str || str.trim().length === 0 || str.match(/^[ ]*$/)) {
    isNoValid = false
  }
  return isNoValid
}

const numberWithCommas = (x, dp) => {
  if (!x && x !== 0) {
    return x
  }
  if (dp) {
    x = Number(x).toFixed(dp)
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const capitalizeFirstLetter = (x, delimiter) => {
  if (!x) {
    return x
  }
  if (delimiter) {
    x = x.replaceAll(`${delimiter}`, ' ')
  }
  x = x.trim().replace(/\s+/g, ' ')
  let words = x.toLowerCase().split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }
  words = words.join(' ')
  return words
}

const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(','); var mime = arr[0].match(/:(.*?);/)[1]
  var bstr = atob(arr[1]); var n = bstr.length; var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

const displayImgCropperRotateBtn = (cropper) => {
  var targetDiv = document.querySelector('.cropper')
  // Create the new div element
  var newDiv = document.createElement('div')
  newDiv.style.position = 'absolute'
  newDiv.style.left = 'calc(50% - 50px)'
  newDiv.style.bottom = '2%'
  newDiv.style.zIndex = 1000

  // Create the button element
  var button = document.createElement('button')
  button.className = 'el-button el-button--primary is-round'
  button.style.color = 'white'
  button.style.background = 'rgba(0,0,0,.5)'
  button.style.border = 'none'
  button.type = 'button'

  var icon = document.createElement('i')
  icon.className = 'el-icon-refresh-right'
  var span = document.createElement('span')
  span.innerHTML = 'Rotate'

  button.addEventListener('click', () => {
    cropper.rotate(90)
  })
  button.appendChild(icon)
  button.appendChild(span)
  newDiv.appendChild(button)

  var referenceNode = targetDiv.firstChild
  targetDiv.insertBefore(newDiv, referenceNode)
}
/**
 * 状态样式
 */
const getStatusTagStyle = (status) => {
  if (status) {
    let style = {}
    const keyArr = status.toUpperCase().split('_')
    if (keyArr.includes('PENDING')) {
      if (keyArr.includes('CANCELLATION')) {
        style = { color: '#f4aaaa', backgroundColor: '#ffeaea' }
      } else if (keyArr.includes('CONFIRMATION')) {
        style = { color: '#fff3d7', backgroundColor: '#eac66c' }
      } else if (keyArr.includes('APPROVAL') || keyArr.includes('AUTHORIZATION')) {
        style = { color: '#FFA551', backgroundColor: 'RGB(255,165,81, .2)' }
      } else {
        style = { color: '#95BFFF', backgroundColor: 'RGB(149,191,255, .2)' }
      }
    } else if (keyArr.includes('IN PROGRESS')) {
      style = { color: '#F4BC2A', backgroundColor: 'RGB(244,188,42, .2)' }
    } else if (keyArr.includes('CONFIRMED')) {
      style = { color: '#07D904', backgroundColor: '#DCFFDB' }
    } else if (keyArr.includes('DRAFT')) {
      style = { color: '#77ABF9', backgroundColor: 'RGB(119,171,249, .5)' }
    } else if (keyArr.includes('SENT') || keyArr.includes('APPLIED') || keyArr.includes('ACKNOWLEDGEMENT')) {
      style = { color: '#1F77FB', backgroundColor: '#D4E6FF' }
    } else if (keyArr.includes('PAID')) {
      style = { color: '#345A73', backgroundColor: '#D4E6FF' }
    } else if (keyArr.includes('OUTSTANDING')) {
      style = { color: '#FF6B4A', backgroundColor: '#FFE1DB' }
    } else if (keyArr.includes('READY')) {
      style = { color: '#F4BC2A', backgroundColor: '#FFCD4E' }
    } else if (keyArr.includes('COMPLETED')) {
      style = { color: '#BA63FF', backgroundColor: 'RGB(186,99,255, .2)' }
    } else if (keyArr.includes('APPROVED') || keyArr.includes('CREATED')) {
      style = { color: '#84D883', backgroundColor: '#DBF8DB' }
    } else if (keyArr.includes('REJECTED')) {
      style = { color: '#F60000', backgroundColor: '#FFDADA' }
    } else if (keyArr.includes('CANCELLED') || keyArr.includes('OVERDUE')) {
      style = { color: '#F26961', backgroundColor: '#FFE6E1' }
    } else {
      style = { color: '#FFA551', backgroundColor: 'RGB(255,165,81, .2)' }
    }
    return style
  }
}
const statusTypeStyle = (status) => {
  switch (status) {
    case 'PENDING_APPROVAL':
      return {
        backgroundColor: '#FFF3E1',
        color: '#FFA551'
      }
    case 'INVOICE_APPROVED' || 'APPROVED':
      return {
        backgroundColor: '#DBF8DB',
        color: '#84D883'
      }
    case 'INVOICE_CREATED': // change
      return {
        backgroundColor: '#EEFFAA',
        color: '#84D883'
      }
    case 'INVOICE_REJECTED' || 'REJECTED':
      return {
        backgroundColor: '#FFE6E1',
        color: '#F26961'
      }
    case 'INVOICE_SENT' || 'APPLIED':
      return {
        backgroundColor: '#DAF9F3',
        color: '#24A3AB'
      }
    case 'OUTSTANDING':
      return {
        backgroundColor: '#FDF0DD',
        color: '#DC765D'
      }
    case 'PAID':
      return {
        backgroundColor: '#DAF5FE',
        color: '#245AAB'
      }
    case 'INVOICE CANCELLED': // change
      return {
        backgroundColor: '#FFE6E1',
        color: '#F26961'
      }
    case 'APPLIED': // change
      return {
        backgroundColor: '#FFE6E1',
        color: '#F26961'
      }
    default: // PENDING_APPROVAL
      return {
        backgroundColor: '#FFF3E1',
        color: '#FFA551'
      }
  }
}
/**
 * 生成随机 id
 */
const getUuiD = (randomLength) => {
  return Number(Math.random().toString().substr(2, randomLength) + Date.now()).toString(36)
}
/**
 * 处理二级目录数据格式
 */
const formatDataCascaderLocation = (arr) => {
  const arrNew = []
  // function getUuiD (randomLength) {
  //   return Number(Math.random().toString().substr(2, randomLength) + Date.now()).toString(36)
  // }
  arr.forEach(item => {
    const location = {}
    location.id = getUuiD(8)
    location.locationCode = item.value
    location.parentLocationId = null
    arrNew.push(location)
    item.children.forEach(child => {
      const childLocation = {}
      childLocation.id = getUuiD(8)
      childLocation.locationCode = child.value
      childLocation.parentLocationId = location.id
      arrNew.push(childLocation)
    }
    )
  })
  return arrNew
}
const getTodayISOString = () => {
  const today = new Date(new Date().setHours(0, 0, 0, 0))
  return today.toISOString()
}
const checkStartEndDate = (startDate, endDate) => {
  if (startDate && endDate) {
    const result = Date.parse(endDate) > Date.parse(startDate)
    return result
  } else {
    return false
  }
}
const formatNullToUnderscore = (data = ([] || {})) => {
  if (Array.isArray(data)) {
    data.forEach(item => {
      const fieldNameList = Object.keys(item)
      fieldNameList.forEach(field => {
        if (!item[field]) {
          item[field] = '_'
        }
      })
    })
  } else {
    const fieldNameList = Object.keys(data)
    fieldNameList.forEach(field => {
      if (!data[field]) {
        data[field] = '_'
      }
    })
  }
  return data
}

const formatUtcTimeString = (utcTime) => {
  let str
  if (utcTime) {
    const ipos = utcTime.indexOf('.')
    str = utcTime.substring(0, ipos).replace('T', '  ')
  } else {
    str = '_'
  }
  return str
}

const reqGreaterThanZeroValidation = (rule, value, callback) =>{
  if ((value === 0)) {
    callback(new Error('Value must be greater than 0'))
  } else if (!value) {
    callback(new Error('This field is required'))
  } else {
    callback()
  }
}

const openBlobUrlInNewTab = (url) => {
  const win = window.open(url)

  //revokeObjectURL by checking if the tab is closed
  const loop = setInterval(() => {
    if(win.closed) {
      URL.revokeObjectURL(url)
      clearInterval(loop);
    }
  }, 5000)
}

const isValidImoNo = (imoNo) => {
  if(!imoNo || imoNo.length != 7 || !/^\d+$/.test(imoNo)) return false
  let sum = 0

  for(let i = 0; i < 6; i++) {
    sum += (imoNo[i].charCodeAt(0) - 48) * (imoNo.length - i)
  }
  return sum % 10 === imoNo.charCodeAt(6) - 48
}

const serverSGDateTimeFormat = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (date === null || date === '') {
    return ''
  }
  return moment(date).utcOffset(8).format(format)
}

const toSgUtcDate = (date) => {
  if(!date) return
  const temp = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()))
  temp.setTime(temp.getTime() + (-480 * 60000))
  return temp
}

const fromSgUtcDate = (dateStr) => {
  if(!dateStr) return
  const date = new Date(dateStr)
  date.setTime(date.getTime() - (-480 * 60000))
  date.setTime(date.getTime() + (date.getTimezoneOffset() * 60000))
  return date
}

const isValidEmail = (email) => {
  const regexRule = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
  if(!email || !regexRule.test(email)) return false
  return true
}
const getObjectDiff = (obj1, obj2) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (Vue.prototype.$_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))
  return diff
}

const convertToQuery = (value) => {
  let str = ''
  for (const key in value) {
    if (key && value[key]) {
      if (str !== '') {
        str += '&'
      }

      if (Array.isArray(value[key])) {
        let keyValue = ''
        value[key].map(x => {
          if (keyValue !== '') {
            keyValue += ','
          }
          keyValue += x
        })
        str += key + '=' + keyValue
      } else {
        str += key + '=' + encodeURIComponent(value[key])
      }
    }
  }

  return str
}
const checkIsNotNullOrEmpty = (str) => {
  return str !== null && str !== undefined && str.trim() !== ''
}
const handleSearchMpaReceivingVessels = async (vesselSearchKey, companyId) => {
  let list = []
  try {
    const res = getReceivingVesselListBySearch(vesselSearchKey, companyId)
    if (res?.code === 1000) {
      list = res.data
    }
  } catch (error) {
  }
  return list
}

export {
  toggleFullScreen,
  sortByAlphabetical,
  getStatusTagStyle,
  statusTypeStyle,
  isValidString,
  numberWithCommas,
  capitalizeFirstLetter,
  dataURLtoBlob,
  displayImgCropperRotateBtn,
  formatDataCascaderLocation,
  getTodayISOString,
  checkStartEndDate,
  getUuiD,
  formatNullToUnderscore,
  formatUtcTimeString,
  reqGreaterThanZeroValidation,
  openBlobUrlInNewTab,
  isValidImoNo,
  serverSGDateTimeFormat,
  toSgUtcDate,
  fromSgUtcDate,
  isValidEmail,
  getObjectDiff,
  convertToQuery,
  checkIsNotNullOrEmpty,
  handleSearchMpaReceivingVessels
}
