var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "loading-container" }, [
        _c(
          "div",
          { staticClass: "center-block" },
          [_c("base-loading"), _c("p", [_vm._v(_vm._s(_vm.text))])],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }