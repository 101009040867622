var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "title-container" }, [
    _vm._v(" " + _vm._s(_vm.title) + " "),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.subTitle,
            expression: "subTitle",
          },
        ],
      },
      [_vm._v(_vm._s(_vm.subTitle))]
    ),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.subTitle2,
            expression: "subTitle2",
          },
        ],
      },
      [_vm._v(_vm._s(_vm.subTitle2))]
    ),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.subTitle3,
            expression: "subTitle3",
          },
        ],
      },
      [_vm._v(_vm._s(_vm.subTitle3))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }