var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company-item", class: { active: _vm.active } },
    [
      _c("span", [_vm._v(_vm._s(_vm.company.companyName))]),
      _c("span", { staticClass: "font-small" }, [
        _vm._v(_vm._s(_vm.getCompanyStatusText(_vm.company))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }