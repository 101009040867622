<template>
  <div class="no-data">
    <img src="@/assets/images/no_data.png" alt="">
    <span>{{ $t('noDataAlt') }}</span>
  </div>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.no-data {
  @include flex-center-column;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  img {
    width: 200px;
    height: 200px;
  }

  span {
    font-size: 16px;
    color: #C0C4CC;
  }
}
</style>
