/**
 * @description 全局配置
 * @author One
 * @version v1.0
 */

// 默认主题配置
// export const defaultTheme = {
//   themeName: 'custom-green-theme', // 主题名称
//   cn_name: '绿色主题', // 主题中文名称
//   primaryColor: '#19CAAD', // 默认主要颜色
//   dangerColor: '#F56C6C', // 错误提示颜色
//   infoColor: '#909399', // 普通提示颜色
//   successColor: '#67C23A', // 成功提示颜色
//   warningColor: '#E6A23C', // 警示提示颜色
//   activeBackgroundColor: '#19CAAD', // 激活的菜单栏背景颜色
//   barBackgroundColor: '#19CAAD', // 顶部操作栏背景颜色
//   barTextColor: '#ffffff', // 顶部操作栏字体颜色
//   logoBackgroundColor: '#19CAAD', // logo区域的背景颜色
//   logoTextColor: '#ffffff', // logo区域的字体颜色
//   menuBackgroundColor: '#ffffff', // 菜单背景颜色（非激活状态）
//   menuTextColor: '#303133' // 菜单字体颜色
// }

export const defaultTheme = {
  themeName: 'custom-blue-deep-theme', // 主题名称
  cn_name: '深蓝主题', // 主题中文名称
  primaryColor: '#0F376A', // 默认主要颜色
  dangerColor: '#CC3333', // 错误提示颜色
  infoColor: '#909399', // 普通提示颜色
  successColor: '#339933', // 成功提示颜色
  warningColor: '#E6A23C', // 警示提示颜色
  activeBackgroundColor: '#ffffff', // 激活的菜单栏背景颜色
  barBackgroundColor: '#FDFDFF', // 顶部操作栏背景颜色
  barTextColor: '#4A4D54', // 顶部操作栏字体颜色
  logoBackgroundColor: '#FDFDFF', // logo区域的背景颜色
  logoTextColor: '#2C3F57', // logo区域的字体颜色
  menuBackgroundColor: '#FDFDFF', // 菜单背景颜色（非激活状态）
  menuTextColor: '#1B1B1B' // 菜单字体颜色
}

// 内置的角色编码配置
export const roleCode = {
  systemAdmin: 'SYSTEM_ADMIN',
  companyAdmin: 'COMPANY_ADMIN',
  companyManager: 'COMPANY_MANAGER',
  teamManager: 'TEAM_MANAGER',
  teamMember: 'TEAM_MEMBER',
  accounts: 'ACCOUNTS',
  ship: 'SHIP'
}

export const permissionFunctionCode = {
  ADD: 'add',
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
  MENU: 'menu',
  TRADER_APPROVAL: 'trader_approval',
  CREDIT_APPROVAL: 'credit_approval',
  ACCOUNTS_APPROVAL: 'accounts_approval',
  AUTHORIZED_SIGNATORY_APPROVAL: 'authorized_signatory_approval',
  MANAGE_USER: 'manage_user',
  INVITE_USER: 'invite_user',
  MANAGE_OFFLINE_USER: 'manage_offline_user'
}

// 订单状态
export const orderStatus = {
  draft: 'DRAFT', // 草稿（自己可见),
  pendingConfirmation: 'PENDING_CONFIRMATION', // 待确认,
  confirmed: 'CONFIRMED', // 已确认,
  pendingCancellation: 'PENDING_CANCELLATION', // 待取消,
  cancelled: 'CANCELLED', // 已取消,
  pendingInvoice: 'PENDING_INVOICE', // 待开票,
  pendingSettled: 'PENDING_SETTLED', // 待结算,
  settled: 'SETTLED', // 已结算,
  archive: 'ARCHIVE', // 已归档
  orderComplete: 'ORDER_COMPLETE',
  verified: 'VERIFIED',
  readyForInvoicing: 'READY_FOR_INVOICING',
  invoiceCreated: 'INVOICE_CREATED',
  pendingCreditAuthorization: 'PENDING_CREDIT_AUTHORIZATION',
  creditAuthorizationRejected: 'CREDIT_AUTHORIZATION_REJECTED'
}

export const orderStatusDesc = {
  draft: 'Draft', // 草稿（自己可见),
  pendingConfirmation: 'Pending confirmation', // 待确认,
  confirmed: 'Confirmed', // 已确认,
  pendingCancellation: 'Pending cancellation', // 待取消,
  cancelled: 'Cancelled', // 已取消,
  pendingInvoice: 'Pending invoice', // 待开票,
  pendingSettled: 'Pending settled', // 待结算,
  settled: 'Settled', // 已结算,
  archive: 'Archive', // 已归档
  orderComplete: 'Order Complete',
  verified: 'Verified',
  readyForInvoicing: 'Ready For Invoicing',
  invoiceCreated: 'Invoice Created',
  pendingCreditAuthorization: "Pending Credit Authorization",
  creditAuthorizationRejected: 'Credit Authorization Rejected'
}
export const orderStatusColor = {
  draft: '#95BFFF', // 草稿（自己可见),
  pendingConfirmation: '#1F77FB', // 待确认,
  confirmed: '#08E204', // 已确认,
  pendingCancellation: '#FF9F9F', // 待取消,
  cancelled: '#F60000', // 已取消,
  pendingInvoice: '#FFE194', // 待开票,
  pendingSettled: '#FFB800', // 待结算,
  settled: '#BA63FF', // 已结算,
  archive: '#e9e9eb', // 已归档
  orderComplete: '#e9e9eb',
  verified: '#e9e9eb',
  readyForInvoicing: '#e9e9eb',
  invoiceCreated: '#e9e9eb',
  pendingCreditAuthorization: '#1fe5fb',
  creditAuthorizationRejected: '#FF9F9F'
}
export const orderStatusNum = {
  0: orderStatus.draft, // 草稿（自己可见),
  1: orderStatus.pendingConfirmation, // 待确认,
  2: orderStatus.confirmed, // 已确认,
  3: orderStatus.pendingCancellation, // 待取消,
  4: orderStatus.cancelled, // 已取消,
  5: orderStatus.pendingInvoice, // 待开票,
  6: orderStatus.pendingSettled, // 待结算,
  7: orderStatus.settled, // 已结算,
  8: orderStatus.archive, // 已归档
  9: orderStatus.orderComplete,
  10: orderStatus.verified,
  11: orderStatus.readyForInvoicing,
  12: orderStatus.invoiceCreated,
  13: orderStatus.pendingCreditAuthorization,
  14: orderStatus.creditAuthorizationRejected
}

