<template>
  <el-popconfirm
    style="margin-left: 10px"
    :title="$t('deletePermanent')"
    :confirmButtonText="$t('confirm')"
    :cancelButtonText="$t('cancel')"
    v-on="$listeners">
    <el-button
      slot="reference"
      :type="$attrs.type?$attrs.type:'text'"
      :size="$attrs.size?$attrs.size:''"
      :disabled="disabled"
      :style="fontColor">
      {{ $t('delete') }}
    </el-button>
  </el-popconfirm>
</template>

<script>
export default {
  name: 'BaseButtonDelete',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fontColor () {
      /**
       * 按钮字体颜色，禁用的删除按钮颜色为灰色，否则为红色
       */
      if (this.disabled) {
        return { color: '#C0C4CC' }
      }
      return { color: this.$store.state.currentTheme.dangerColor }
    }
  }
}
</script>

<style scoped>

</style>
