var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popconfirm",
    _vm._g(
      {
        staticStyle: { "margin-left": "10px" },
        attrs: {
          title: _vm.$t("deletePermanent"),
          confirmButtonText: _vm.$t("confirm"),
          cancelButtonText: _vm.$t("cancel"),
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "el-button",
        {
          style: _vm.fontColor,
          attrs: {
            slot: "reference",
            type: _vm.$attrs.type ? _vm.$attrs.type : "text",
            size: _vm.$attrs.size ? _vm.$attrs.size : "",
            disabled: _vm.disabled,
          },
          slot: "reference",
        },
        [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }