export default {
  /**
   * 日期时间格式化到秒
   * @param {String} value:日期时间字符串
   */
  dateTimeFormat (value) {
    if (value) {
      const dataStr = value.replace(/-/g, '/')
      const date = new Date(dataStr)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours().toString().padStart(2, '0')
      const minute = date.getMinutes().toString().padStart(2, '0')
      const second = date.getSeconds().toString().padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }
    return ''
  },

  /**
   * 日期时间格式化分
   * @param {String} value:日期时间字符串
   */
  dateTimeFormatMinute (value) {
    if (value) {
      const dataStr = value.replace(/-/g, '/')
      const date = new Date(dataStr)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours().toString().padStart(2, '0')
      const minute = date.getMinutes().toString().padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}`
    }
    return ''
  },

  /**
   * 日期格式化
   * @param {String} value:日期时间字符串
   */
  dateFormat (value) {
    if (value) {
      const dataStr = value.replace(/-/g, '/')
      const date = new Date(dataStr)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${day}-${month}-${year}`
    }
    return ''
  },

  /**
   * 时间戳转日期
   * @param {String} time:时间戳
   */
  timestamp2Date (time) {
    if (time) {
      const date = new Date(parseInt(time))
      const year = date.getFullYear()
      const mon = date.getMonth() + 1
      const day = date.getDate()
      return year + '-' + mon + '-' + day
    }
    return ''
  },

  /**
   * 用户类型格式化
   * @param {Array} value:roles list
   */
  userTypeFormat (value) {
    const userTypeList = {
      SYSTEM_ADMIN: 'SA',
      COMPANY_ADMIN: 'CA',
      COMPANY_MANAGER: 'CM',
      TEAM_MEMBER: 'TM'
    }
    const roles = (value || []).map(item => userTypeList[item.code])
    return roles.join('/')
  },

  /**
   * 订单状态格式化
   * @param {String} status:订单状态枚举字符串值
   */
  orderStatusFormat (status) {
    const text = {
      0: 'draft',
      1: 'Pending Confirmation',
      2: 'Confirmed',
      3: 'Pending Cancel',
      4: 'Cancelled',
      5: 'Pending Invoice',
      6: 'Pending Settled',
      7: 'Settled',
      8: 'Archive',
      13: 'Pending Credit Authorization',
      14: 'Credit Authorization Rejected'
    }
    return [null, undefined].includes(status) ? '' : text[status]
  }
}
