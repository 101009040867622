<template>
  <el-dialog
    custom-class="search-dialog"
    :visible.sync="visible"
    width="50%"
    top="15vh"
    append-to-body
    v-on="$listeners">
    <el-tabs class="search-tabs" v-model="tabName">
      <el-tab-pane :label="$t('generalQuery')" name="first">
        <div style="margin:0 40px 0 20px">
          <el-row :gutter="20">
            <template
              v-for="(item,index) in commonSearchFormData">
              <el-col
                :span="12"
                :key="index">
                <el-form ref="form" :label-width="$i18n.locale==='cn'?'125px':'220px'">
                  <el-form-item :label="$t(item.fieldDesc)">
                    <el-date-picker
                      v-if="['date','datetime'].includes(item.fieldType)"
                      v-model="item.value"
                      type="date"
                      size="small"
                      value-format="yyyy-MM-dd"
                      :placeholder="$t('selectDate')">
                    </el-date-picker>
                    <el-input
                      v-else
                      v-model="item.value"
                      size="small">
                    </el-input>
                  </el-form-item>
                </el-form>
              </el-col>
            </template>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('advancedQuery')" name="second">
        <div style="padding: 0 50px">
          <el-row
            class="advanced-search"
            :gutter="20"
            v-for="(item,index) in advancedSearchFormData"
            :key="index">
            <el-col :span="6">
              <el-select
                v-model="item.fieldName"
                required
                size="small"
                :placeholder="$t('selectQueryField')">
                <template v-for="(field,index1) in searchFields">
                  <el-option
                    :key="index1"
                    :label="$t(field.fieldDesc)"
                    :value="field.fieldName">
                  </el-option>
                </template>
              </el-select>
            </el-col>
            <el-col :span="5">
              <el-select
                v-model="item.calculator"
                size="small"
                :placeholder="$t('queryCondition')">
                <el-option
                  v-for="calculator in calculators"
                  :key="calculator.value"
                  :label="calculator.name"
                  :value="calculator.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-input
                v-model="item.value"
                size="small"
                :placeholder="$t('value')">
              </el-input>
            </el-col>
            <el-col :span="5">
              <el-select
                clearable
                size="small"
                :placeholder="$t('and')"
                v-model="item.connector">
                <el-option
                  v-for="connector in connectors"
                  :key="connector.value"
                  :label="connector.name"
                  :value="connector.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="2">
              <em
                class="el-icon-remove"
                @click="deleteCondition(index)">
              </em>
            </el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="24" style="text-align: center">
              <el-button
                style="margin-top: 10px"
                round
                size="mini"
                icon="el-icon-plus"
                @click="addCondition">
                {{$t('addCondition')}}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button
        size="mini"
        @click="reset">
        {{$t('reset')}}
      </el-button>
      <el-button
        type="primary"
        size="mini"
        @click="search">
        {{$t('query')}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'CommonQuery',
  props: {
    fields: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      tabName: 'first',
      // 普通搜索数据
      commonSearchFormData: [],
      // 高级搜索数据
      advancedSearchFormData: [{ fieldName: '', calculator: '', value: '', connector: 'AND' }],
      calculators: [
        { name: this.$t('equal'), value: '=' },
        { name: this.$t('greaterThan'), value: '>' },
        { name: this.$t('lessThan'), value: '<' },
        { name: 'like', value: 'like' },
        { name: this.$t('notEqual'), value: '<>' },
        { name: this.$t('includedIn'), value: 'IN' }
      ],
      connectors: [
        { name: this.$t('and'), value: 'AND' },
        { name: this.$t('or'), value: 'OR' }
      ],
      searchFields: []
    }
  },
  mounted () {
    this.visible = true
  },
  methods: {
    // 添加条件
    addCondition () {
      const condition = {
        fieldName: '',
        calculator: '',
        value: '',
        connector: 'AND'
      }
      this.advancedSearchFormData.push(condition)
    },
    // 删除条件
    deleteCondition (index) {
      this.$delete(this.advancedSearchFormData, index)
    },

    search () {
      let conditions
      if (this.tabName === 'first') {
        const formData = this.commonSearchFormData.filter(item => {
          return ![null, '', undefined].includes(item.value)
        })
        const dateConditions = []
        formData.forEach(item => {
          if (['date', 'datetime'].includes(item.fieldType)) {
            const dateCondition = {
              fieldName: item.fieldName,
              calculator: '<',
              value: item.value + ' 23:59:59',
              connector: 'AND'
            }
            item.calculator = '>'
            item.value = item.value + ' 00:00:00'
            dateConditions.push(dateCondition)
          }
        })
        if (formData.length) {
          conditions = [...formData, ...dateConditions]
        } else {
          return
        }
      } else {
        const isMissing = this.advancedSearchFormData.some((item) => {
          return !(item.fieldName && item.calculator && item.value)
        })
        if (isMissing) {
          this.$message.error(this.$t('inquiryInfoIncomplete'))
          return
        }
        conditions = this.advancedSearchFormData
      }
      conditions.forEach(item => {
        delete item.fieldDesc
        delete item.fieldType
      })
      this.$emit('reportCondition', conditions)
      this.visible = false
    },
    reset () {
      this.tabName === 'first'
        ? this.$options.watch.fields.handler.call(this, this.fields)
        : this.advancedSearchFormData = [{ fieldName: '', calculator: '', value: '', connector: 'AND' }]
    }
  },
  watch: {
    fields: {
      handler (newValue) {
        this.commonSearchFormData = []
        newValue.forEach(category => {
          category.fields.forEach(item => {
            if (item.fieldName !== 'id' && item.fieldType !== 'table') {
              const condition = {
                fieldName: item.fieldName,
                fieldDesc: item.label || item.fieldName,
                fieldType: item.formConfig?.type,
                calculator: '=',
                value: '',
                connector: 'AND'
              }
              const field = {
                fieldName: item.fieldName,
                fieldType: item.formConfig?.type,
                fieldDesc: item.label || item.fieldName
              }
              this.commonSearchFormData.push(condition)
              this.searchFields.push(field)
            }
          })
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>

  .search-dialog {

    .el-dialog__body {
      padding: 0;

      * {
        font-size: 12px;
      }

      .el-date-editor {
        width: 100%;
      }

      .el-tabs__content {
        max-height: calc(70vh - 148px);
        padding-top: 20px;
        overflow-y: auto;
      }
    }

    .el-dialog__footer {
      padding-right: 30px;
      padding-bottom: 25px;
    }

    .search-tabs {
      // .el-tabs__item.is-top {
      //   font-size: 14px;
      //   font-weight: bold;
      // }

      /deep/ .el-tabs__nav-scroll {
        display: flex;

        .el-tabs__nav {
          margin: 0 auto;
        }
      }

      .advanced-search {
        margin: 10px 0;
      }

      /deep/ .el-tabs__nav-wrap::after {
        height: 0 !important;
      }
    }

    .el-icon-remove {
      margin-top: 6px;
      font-size: 20px;
      color: #F56C6C;
      cursor: pointer;
    }
  }

</style>
