var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-divider", { staticClass: "el-divider-border" }),
      _c(
        "div",
        {
          staticClass: "company-item-container",
          class: { active: _vm.active },
        },
        [
          _c("div", { staticClass: "company-item-baseInfo" }, [
            !_vm.isHideLogo
              ? _c("div", [
                  _vm.logoSrc
                    ? _c("img", {
                        attrs: { height: "35px", src: _vm.logoSrc, alt: "" },
                      })
                    : _c(
                        "div",
                        [
                          _c("el-avatar", { attrs: { size: 35 } }, [
                            _vm._v(
                              _vm._s(_vm.getAvatarName(_vm.company.companyName))
                            ),
                          ]),
                        ],
                        1
                      ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "companyName" }, [
              _c("p", [_vm._v(_vm._s(_vm.company.companyName))]),
            ]),
          ]),
          _c(
            "div",
            [
              _vm.tagText
                ? _c("el-tag", { attrs: { type: _vm.tagType } }, [
                    _vm._v(_vm._s(_vm.tagText)),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }