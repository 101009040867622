<template>
  <div></div>
</template>

<script>
export default {
  name: 'index',
  data () {
    return {
      instance: null
    }
  },
  mounted () {
    window.addEventListener('offline', this.eventHandler)
    window.addEventListener('online', this.eventHandler)
  },
  methods: {
    eventHandler (event) {
      const type = event.type
      if (type === 'online') {
        this.instance.close()
        this.$notify.success({
          title: 'Network',
          message: 'The network is back to normal',
          duration: 0
        })
      } else {
        this.instance = this.$notify.error({
          title: 'Network',
          message: 'The network seems to be disconnected',
          duration: 0
        })
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('offline', this.eventHandler)
    window.removeEventListener('online', this.eventHandler)
  }
}
</script>

<style lang="scss" scoped>
.offline {
  .tip {
    height: 40vh;
    padding: 40px;

    img {
      width: 300px;
      height: 300px;
    }
  }
}
</style>
