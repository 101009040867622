<template>
  <el-dialog
    v-fullscreen
    custom-class="base-form"
    :visible.sync="dialogVisible"
    :width="width"
    :top="top"
    :close-on-click-modal="false"
    v-on="$listeners">
    <span slot="title" class="base-title-font">
      <em
        :style="{color:$store.state.currentTheme.primaryColor}"
        class="iconfont icon-yuandianzhong">
      </em>
      {{ $t(formData.id ? 'edit' : 'add') }}
    </span>
    <div class="body">
      <el-form
      class="el-form-item-left-lable "
        ref="form"
        :model="formData"
        :rules="rules"
        :label-width="labelWidth">
        <template v-for="item in fields">
          <el-form-item
            v-if="item.fieldType !== 'invisible'"
            :label="$t(item.label || item.fieldName)"
            :prop="item.fieldName"
            :key="item.fieldName"
            :rules="getRules(item)">
            <!--普通输入框-->
            <el-input
              v-if="!item.formConfig  || ['input','password'].includes(item.formConfig.type)"
              v-model="formData[item.fieldName]"
              :show-password="item.formConfig && item.formConfig.type==='password'"
              :disabled="item.disabled">
            </el-input>
            <phone-input
              v-else-if="item.formConfig.type === 'phone'"
              v-model=formData[item.fieldName]>
            </phone-input>
            <!--数字输入框-->
            <el-input
              v-else-if="item.formConfig.type === 'inputNumber'"
              v-model.number="formData[item.fieldName]"
              :min="item.formConfig.min || -Infinity"
              :max="item.formConfig.max || Infinity"
              :disabled="item.disabled">
            </el-input>
            <!--单选组-->
            <el-radio-group
              v-else-if="item.formConfig.type === 'radio'"
              v-model="formData[item.fieldName]"
              :disabled="item.disabled">
              <el-radio
                v-for="(option,index) in item.formConfig.options"
                :key="index"
                :label="option.value">
                {{ $t(option.text) }}
              </el-radio>
            </el-radio-group>
            <!--多选组-->
            <el-checkbox-group
              v-else-if="item.formConfig.type === 'checkbox'"
              v-model="formData[item.fieldName]"
              :disabled="item.disabled">
              <el-checkbox
                v-for="(option,index) in item.formConfig.options"
                :key="index"
                :label="option.value">
                {{ $t(option.text) }}
              </el-checkbox>
            </el-checkbox-group>
            <!--下拉选择框-->
            <el-select
              filterable
              :clearable="item.formConfig.clearable === true"
              v-else-if="item.formConfig.type === 'select'"
              v-model="formData[item.fieldName]"
              :disabled="item.disabled">
              <el-option
                v-for="option in item.formConfig.options"
                :key="option.value"
                :label="option.label || option.value"
                :value="option.value">
              </el-option>
            </el-select>
            <!--级联选择器-->
            <el-cascader
              v-else-if="item.formConfig.type === 'cascader'"
              v-model="formData[item.fieldName]"
              :options="item.formConfig.options"
              :show-all-levels="item.formConfig.showAllLevels===undefined?true:item.formConfig.showAllLevels"
              :props="item.formConfig.props"
              :disabled="item.disabled">
            </el-cascader>
            <!--开关-->
            <el-switch
              v-else-if="item.formConfig.type === 'switch'"
              v-model="formData[item.fieldName]"
              :active-text="item.formConfig.activeText || ''"
              :inactive-text="item.formConfig.inactiveText || ''"
              :disabled="item.disabled"
              @change="item.changeEvent ? item.changeEvent($event) : (()=>{})">
            </el-switch>
            <!--日期选择器-->
            <el-date-picker
              v-else-if="['date','datetime'].includes(item.formConfig.type)"
              v-model="formData[item.fieldName]"
              :type="item.formConfig.type"
              :value-format="item.formConfig.type==='date'?'yyyy-MM-dd':'yyyy-MM-dd HH:mm:ss'"
              :disabled="item.disabled">
            </el-date-picker>
            <!--时间选择器-->
            <el-time-picker
              v-else-if="item.formConfig.type === 'time'"
              v-model="formData[item.fieldName]"
              value-format="HH:mm:ss"
              :disabled="item.disabled">
            </el-time-picker>
            <!--图标选择器-->
            <icon-picker
              v-else-if="item.formConfig.type === 'icon'"
              v-model="formData[item.fieldName]"
              :disabled="item.disabled"
              >
            </icon-picker>
             <!--文件上传, 2张-->
             <el-upload
              v-model="formData[item.fieldName]"
              v-else-if="item.formConfig.type === 'upload'"
              :limit="2"
              :action="fileUploadUrl"
              accept=".jpg,.png"
              list-type="picture"
              :file-list="item.formConfig.fileList ? item.formConfig.fileList : []"
              :on-remove="item.formConfig.handleRemove"
              :before-remove="item.formConfig.beforeRemove"
              :on-exceed="item.formConfig.handleExceed"
              :on-success="item.formConfig.handleSuccess"
              >
              <el-button class="primary-button" >{{item.formConfig.desc}}</el-button>
              <div slot="tip" class="el-upload__tip">{{ item.formConfig.message}}</div>
            </el-upload>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <div slot="footer">
      <el-button
        size="meduim"
        round
        @click="dialogVisible = false">
        {{ $t('cancel') }}
      </el-button>
      <el-button
        size="meduim"
        round
        type="primary"
        :loading="loading"
        @click="submit">
        {{ $t('submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import IconPicker from '@/components/IconPicker'

export default {
  name: 'BaseFormAdd',
  components: {
    IconPicker
  },
  computed: {
    labelWidth () {
      if (this.$i18n.locale === 'cn') {
        return this.$attrs.labelWidthCn ? `${this.$attrs.labelWidthCn}px` : '80px'
      }
      return this.$attrs.labelWidthEn ? `${this.$attrs.labelWidthEn}px` : '160px'
    }
  },
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    },
    urlSave: {
      type: String,
      required: true
    },
    urlUpdate: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '40%'
    },
    top: {
      type: String,
      default: '10vh'
    }
  },
  data () {
    const formData = {}
    this.fields.forEach(field => {
      formData[field.fieldName] = field.default === undefined ? undefined : field.default
    })
    return {
      formData,
      dialogVisible: false,
      loading: false,
      fileUploadUrl: null
    }
  },
  mounted () {
    this.dialogVisible = this.$attrs.visible
    this.fileUploadUrl = this.$apis.baseUrl + this.$apis.uploadFile
  },
  methods: {
    getRules (item) {
      /**
       * 获取校验规则
       */
      const originalRules = item.rules
      if (originalRules) {
        // 如果存在验证规则，继续操作
        if (originalRules === 'required') {
          return [{
            required: true,
            message: this.$t('rejectBeEmpty'),
            trigger: 'change'
          }]
        } else if (typeof originalRules === 'object') {
          return originalRules
        }
      }
      return undefined
    },
    submit () {
      /**
       * 提交数据
       */
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const url = this.formData.id ? this.urlUpdate : this.urlSave
          Object.keys(this.formData).forEach(key => {
            if (key === 'stampId') {
              this.formData[key] = this.fields[this.fields.findIndex(item => item.fieldName === key)].default
            }
            if (this.formData[key] === null) {
              delete this.formData[key]
            }
          })
          this.$request.post({
            url,
            data: this.formData
          }).then(data => {
            if (data?.code === 1000) {
              this.$emit('submit-success')
              this.$message.success(this.$t('saveSuccess'))
              this.dialogVisible = false
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.base-form {
  .body {
    padding: 20px;
  }
}
</style>
