var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        directives: [{ name: "fullscreen", rawName: "v-fullscreen" }],
        attrs: {
          "custom-class": "base-form",
          visible: _vm.dialogVisible,
          width: _vm.width,
          top: _vm.top,
          "close-on-click-modal": false,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "span",
        {
          staticClass: "base-title-font",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("em", {
            staticClass: "iconfont icon-yuandianzhong",
            style: { color: _vm.$store.state.currentTheme.primaryColor },
          }),
          _vm._v(" " + _vm._s(_vm.$t(_vm.formData.id ? "edit" : "add")) + " "),
        ]
      ),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "el-form-item-left-lable",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": _vm.labelWidth,
              },
            },
            [
              _vm._l(_vm.fields, function (item) {
                return [
                  item.fieldType !== "invisible"
                    ? _c(
                        "el-form-item",
                        {
                          key: item.fieldName,
                          attrs: {
                            label: _vm.$t(item.label || item.fieldName),
                            prop: item.fieldName,
                            rules: _vm.getRules(item),
                          },
                        },
                        [
                          !item.formConfig ||
                          ["input", "password"].includes(item.formConfig.type)
                            ? _c("el-input", {
                                attrs: {
                                  "show-password":
                                    item.formConfig &&
                                    item.formConfig.type === "password",
                                  disabled: item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "phone"
                            ? _c("phone-input", {
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "inputNumber"
                            ? _c("el-input", {
                                attrs: {
                                  min: item.formConfig.min || -Infinity,
                                  max: item.formConfig.max || Infinity,
                                  disabled: item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      item.fieldName,
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "radio"
                            ? _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: item.disabled },
                                  model: {
                                    value: _vm.formData[item.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        item.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[item.fieldName]",
                                  },
                                },
                                _vm._l(
                                  item.formConfig.options,
                                  function (option, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: index,
                                        attrs: { label: option.value },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(option.text)) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : item.formConfig.type === "checkbox"
                            ? _c(
                                "el-checkbox-group",
                                {
                                  attrs: { disabled: item.disabled },
                                  model: {
                                    value: _vm.formData[item.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        item.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[item.fieldName]",
                                  },
                                },
                                _vm._l(
                                  item.formConfig.options,
                                  function (option, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: index,
                                        attrs: { label: option.value },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(option.text)) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : item.formConfig.type === "select"
                            ? _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable:
                                      item.formConfig.clearable === true,
                                    disabled: item.disabled,
                                  },
                                  model: {
                                    value: _vm.formData[item.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        item.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[item.fieldName]",
                                  },
                                },
                                _vm._l(
                                  item.formConfig.options,
                                  function (option) {
                                    return _c("el-option", {
                                      key: option.value,
                                      attrs: {
                                        label: option.label || option.value,
                                        value: option.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : item.formConfig.type === "cascader"
                            ? _c("el-cascader", {
                                attrs: {
                                  options: item.formConfig.options,
                                  "show-all-levels":
                                    item.formConfig.showAllLevels === undefined
                                      ? true
                                      : item.formConfig.showAllLevels,
                                  props: item.formConfig.props,
                                  disabled: item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "switch"
                            ? _c("el-switch", {
                                attrs: {
                                  "active-text":
                                    item.formConfig.activeText || "",
                                  "inactive-text":
                                    item.formConfig.inactiveText || "",
                                  disabled: item.disabled,
                                },
                                on: {
                                  change: function ($event) {
                                    item.changeEvent
                                      ? item.changeEvent($event)
                                      : () => {}
                                  },
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : ["date", "datetime"].includes(
                                item.formConfig.type
                              )
                            ? _c("el-date-picker", {
                                attrs: {
                                  type: item.formConfig.type,
                                  "value-format":
                                    item.formConfig.type === "date"
                                      ? "yyyy-MM-dd"
                                      : "yyyy-MM-dd HH:mm:ss",
                                  disabled: item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "time"
                            ? _c("el-time-picker", {
                                attrs: {
                                  "value-format": "HH:mm:ss",
                                  disabled: item.disabled,
                                },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "icon"
                            ? _c("icon-picker", {
                                attrs: { disabled: item.disabled },
                                model: {
                                  value: _vm.formData[item.fieldName],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.fieldName, $$v)
                                  },
                                  expression: "formData[item.fieldName]",
                                },
                              })
                            : item.formConfig.type === "upload"
                            ? _c(
                                "el-upload",
                                {
                                  attrs: {
                                    limit: 2,
                                    action: _vm.fileUploadUrl,
                                    accept: ".jpg,.png",
                                    "list-type": "picture",
                                    "file-list": item.formConfig.fileList
                                      ? item.formConfig.fileList
                                      : [],
                                    "on-remove": item.formConfig.handleRemove,
                                    "before-remove":
                                      item.formConfig.beforeRemove,
                                    "on-exceed": item.formConfig.handleExceed,
                                    "on-success": item.formConfig.handleSuccess,
                                  },
                                  model: {
                                    value: _vm.formData[item.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        item.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[item.fieldName]",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { staticClass: "primary-button" },
                                    [_vm._v(_vm._s(item.formConfig.desc))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [_vm._v(_vm._s(item.formConfig.message))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "meduim", round: "" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "meduim",
                round: "",
                type: "primary",
                loading: _vm.loading,
              },
              on: { click: _vm.submit },
            },
            [_vm._v(" " + _vm._s(_vm.$t("submit")) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }