<template>
  <div class="title-container">
    {{ title }}
    <span v-show="subTitle">{{ subTitle }}</span>
    <span v-show="subTitle2">{{ subTitle2 }}</span>
    <span v-show="subTitle3">{{ subTitle3 }}</span>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    title: String,
    subTitle: [String, Number],
    subTitle2: [String, Number],
    subTitle3: [String, Number]
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.title-container {
  display: inline-block;
  padding: 8px 15px;
  font-size: 15px;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  background-color: $primary-color;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, 0.08);

  span {
    display: inline-block;
    margin-left: 10px;
    padding: 1px 6px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: lighter;
    color: $primary-color;
    background-color: white;
  }
}
</style>
