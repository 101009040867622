var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          visible: _vm.dialogVisible,
          "custom-class": "file-preview",
          top: "5vh",
          width: "910px",
          "show-close": false,
          "close-on-click-modal": false,
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
          close: function ($event) {
            return _vm.$emit("update:visible", false)
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "file-container" }, [
        _c(
          "div",
          { staticClass: "btn" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small", round: "" },
                on: { click: _vm.print },
              },
              [_vm._v(" " + _vm._s(_vm.$t("print")) + " ")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "success", size: "small", round: "" },
                on: { click: _vm.download },
              },
              [_vm._v(" " + _vm._s(_vm.$t("download")) + " ")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "danger", size: "small", round: "" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("update:visible", false)
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]
            ),
          ],
          1
        ),
        _vm.src
          ? _c("iframe", {
              ref: "iframe",
              attrs: { src: _vm.src, frameborder: "0" },
              on: { load: _vm.handleLoad, error: _vm.handlerError },
            })
          : _vm._e(),
        _vm.loading
          ? _c("div", { staticClass: "loading" }, [
              _c("em", { staticClass: "el-icon-loading" }),
              _c("span", [_vm._v("Loading...")]),
            ])
          : _vm._e(),
        _vm.error
          ? _c("div", { staticClass: "error" }, [
              _c("em", { staticClass: "el-icon-error" }),
              _c("span", [_vm._v("Loading failed")]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }