var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "no-data" }, [
    _c("img", {
      attrs: { src: require("@/assets/images/no_data.png"), alt: "" },
    }),
    _c("span", [_vm._v(_vm._s(_vm.$t("noDataAlt")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }