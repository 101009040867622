var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-start",
        title: _vm.$t("icon"),
        width: "600",
        trigger: "click",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "icon-list" },
        [
          _vm._l(_vm.icons, function (iconItem) {
            return [
              _c("em", {
                key: iconItem,
                class: iconItem,
                on: {
                  click: function ($event) {
                    return _vm.selectIcon(iconItem)
                  },
                },
              }),
            ]
          }),
        ],
        2
      ),
      _c("el-input", {
        attrs: {
          slot: "reference",
          value: _vm.value,
          "suffix-icon": _vm.value,
        },
        slot: "reference",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }