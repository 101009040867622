var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "title" }, [
    _c("em", {
      staticClass: "iconfont",
      class: _vm.fontClass,
      style: { color: _vm.$store.state.currentTheme.primaryColor },
    }),
    _c(
      "span",
      {
        staticClass: "text",
        style: { fontSize: _vm.fontSize, fontWeight: _vm.bold },
      },
      [_vm._v(" " + _vm._s(_vm.text) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }