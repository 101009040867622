var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "business-form-container" }, [
    _c(
      "div",
      { staticClass: "field-form" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              size: "mini",
              model: _vm.formData,
              "show-message": false,
              disabled: _vm.disabled,
              "label-position": "top",
              "label-width": _vm.labelWidth,
            },
          },
          [
            _vm._l(_vm.fields, function (category) {
              return [
                _c(
                  "el-row",
                  {
                    key: category.categoryName,
                    staticClass: "el-row-category",
                    attrs: { gutter: 20 },
                  },
                  [
                    _c("div", { staticClass: "category-name" }, [
                      _vm._v(_vm._s(_vm.$t(category.categoryName))),
                    ]),
                    _vm._l(category.fields, function (item, index) {
                      return [
                        item.fieldType !== "invisible"
                          ? _c(
                              "el-col",
                              {
                                key: item.fieldName + index,
                                attrs: { span: item.colSpan || _vm.span },
                              },
                              [
                                item.fieldType !== "table"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            item.label || item.fieldName
                                          ),
                                          prop: item.fieldName,
                                          "label-width": item.formConfig
                                            ? item.formConfig.labelWidth
                                              ? item.formConfig.labelWidth
                                              : _vm.labelWidth
                                            : _vm.labelWidth,
                                          rules: _vm.getRules(item),
                                        },
                                      },
                                      [
                                        !item.formConfig ||
                                        !item.formConfig.type ||
                                        item.formConfig.type === "input"
                                          ? _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  disabled: !!item.disabled,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.onInput(
                                                      $event,
                                                      item
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.formData[
                                                      item.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      item.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData[item.fieldName]",
                                                },
                                              },
                                              [
                                                item.formConfig &&
                                                item.formConfig.prepend
                                                  ? _c(
                                                      "template",
                                                      { slot: "prepend" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.formConfig
                                                              .prepend
                                                          ) + " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.formConfig &&
                                                item.formConfig.append
                                                  ? _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.formConfig
                                                              .append
                                                          ) + " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : item.formConfig.type === "usd"
                                          ? _c(
                                              "el-input",
                                              {
                                                staticClass: "usd-input",
                                                attrs: {
                                                  size: "mini",
                                                  disabled: !!item.disabled,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.onInput(
                                                      $event,
                                                      item
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.formData[
                                                      item.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      item.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData[item.fieldName]",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "usd-prefix",
                                                    attrs: { slot: "prefix" },
                                                    slot: "prefix",
                                                  },
                                                  [_vm._v("US $")]
                                                ),
                                              ]
                                            )
                                          : item.formConfig.type === "textarea"
                                          ? _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                rows: 3,
                                                disabled: !!item.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.onInput(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[item.fieldName],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    item.fieldName,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData[item.fieldName]",
                                              },
                                            })
                                          : item.formConfig.type ===
                                            "inputNumber"
                                          ? _c("el-input-number", {
                                              attrs: {
                                                size: "mini",
                                                min:
                                                  item.formConfig.min ||
                                                  -Infinity,
                                                max:
                                                  item.formConfig.max ||
                                                  Infinity,
                                                disabled: !!item.disabled,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.onInput(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[item.fieldName],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    item.fieldName,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData[item.fieldName]",
                                              },
                                            })
                                          : item.formConfig.type === "radio"
                                          ? _c(
                                              "el-radio-group",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                },
                                                attrs: {
                                                  disabled: !!item.disabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.formData[
                                                      item.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      item.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData[item.fieldName]",
                                                },
                                              },
                                              _vm._l(
                                                item.formConfig.options,
                                                function (option, index) {
                                                  return _c(
                                                    "el-radio",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        label: option.value,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              option.label ||
                                                                option.value
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : item.formConfig.type === "checkbox"
                                          ? _c(
                                              "el-checkbox-group",
                                              {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                },
                                                attrs: {
                                                  disabled: !!item.disabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.formData[
                                                      item.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      item.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData[item.fieldName]",
                                                },
                                              },
                                              _vm._l(
                                                item.formConfig.options,
                                                function (option, index) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        label: option.value,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              option.label ||
                                                                option.value
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : item.formConfig.type === "select"
                                          ? _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  size: "mini",
                                                  filterable: "",
                                                  disabled: !!item.disabled,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onSelectChange(
                                                      $event,
                                                      item
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.formData[
                                                      item.fieldName
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData,
                                                      item.fieldName,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData[item.fieldName]",
                                                },
                                              },
                                              _vm._l(
                                                item.formConfig.options,
                                                function (option) {
                                                  return _c("el-option", {
                                                    key: option.value,
                                                    attrs: {
                                                      label:
                                                        option.label ||
                                                        option.value,
                                                      value: option.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : item.formConfig.type === "cascader"
                                          ? _c("el-cascader", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                options:
                                                  item.formConfig.options,
                                                "show-all-levels":
                                                  item.formConfig
                                                    .showAllLevels === undefined
                                                    ? true
                                                    : item.formConfig
                                                        .showAllLevels,
                                                props: item.formConfig.props,
                                                disabled: !!item.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[item.fieldName],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    item.fieldName,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData[item.fieldName]",
                                              },
                                            })
                                          : item.formConfig.type === "switch"
                                          ? _c("el-switch", {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                "active-text":
                                                  item.formConfig.activeText ||
                                                  "",
                                                "inactive-text":
                                                  item.formConfig
                                                    .inactiveText || "",
                                                disabled: !!item.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[item.fieldName],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    item.fieldName,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData[item.fieldName]",
                                              },
                                            })
                                          : ["date", "datetime"].includes(
                                              item.formConfig.type
                                            )
                                          ? _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                type: item.formConfig.type,
                                                "value-format":
                                                  "yyyy-MM-dd 12:00:00",
                                                disabled: !!item.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[item.fieldName],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    item.fieldName,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData[item.fieldName]",
                                              },
                                            })
                                          : item.formConfig.type === "time"
                                          ? _c("el-time-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                "value-format": "HH:mm:ss",
                                                disabled: !!item.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[item.fieldName],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    item.fieldName,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData[item.fieldName]",
                                              },
                                            })
                                          : item.formConfig.type ===
                                            "select-table"
                                          ? _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                disabled: !!item.disabled,
                                                placeholder:
                                                  _vm.$t("pleaseSelect"),
                                              },
                                              on: {
                                                focus: function ($event) {
                                                  return item.handler(
                                                    $event,
                                                    _vm.$vnode.context
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[item.fieldName],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    item.fieldName,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData[item.fieldName]",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.fieldType === "table"
                                  ? _c(
                                      "div",
                                      { staticClass: "auto-table" },
                                      [
                                        _c("div", { staticClass: "title" }, [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  item.label || item.fieldName
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !(
                                                    _vm.disabled ||
                                                    item.disabled
                                                  ),
                                                  expression:
                                                    "!(disabled || item.disabled)",
                                                },
                                              ],
                                            },
                                            [
                                              _c("em", {
                                                staticClass:
                                                  "el-icon-circle-plus-outline",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addTableRow(
                                                      _vm.formData[
                                                        item.fieldName
                                                      ],
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("em", {
                                                staticClass:
                                                  "el-icon-remove-outline",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteTableRow(
                                                      _vm.formData[
                                                        item.fieldName
                                                      ],
                                                      item.fieldName
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "el-table",
                                          {
                                            ref: item.fieldName,
                                            refInFor: true,
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              border: "",
                                              size: "small",
                                              data: _vm.formData[
                                                item.fieldName
                                              ],
                                              "show-summary":
                                                !!item.summaryFields,
                                              "summary-method":
                                                _vm.getSummaries(
                                                  item.summaryFields
                                                ),
                                            },
                                            on: {
                                              "row-click": function ($event) {
                                                return _vm.onRowClick(
                                                  $event,
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            !_vm.disabled
                                              ? _c("el-table-column", {
                                                  attrs: {
                                                    type: "selection",
                                                    align: "center",
                                                    width: "55",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._l(
                                              item.fields,
                                              function (field) {
                                                return _c("el-table-column", {
                                                  key: field.fieldName,
                                                  attrs: {
                                                    width: field.width,
                                                    align: "center",
                                                    prop: field.fieldName,
                                                    label: _vm.$t(
                                                      field.label ||
                                                        field.fieldName
                                                    ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  "label-width":
                                                                    "0",
                                                                  prop: `${item.fieldName}.${scope.$index}.${field.fieldName}`,
                                                                  rules:
                                                                    _vm.getRules(
                                                                      field
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                !field.formConfig ||
                                                                !field
                                                                  .formConfig
                                                                  .type ||
                                                                field.formConfig
                                                                  .type ===
                                                                  "input"
                                                                  ? _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          size: "mini",
                                                                          type: "text",
                                                                          disabled:
                                                                            !!field.disabled,
                                                                        },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.onInput(
                                                                                $event,
                                                                                field
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row[
                                                                              field
                                                                                .fieldName
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                field.fieldName,
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row[field.fieldName]",
                                                                        },
                                                                      },
                                                                      [
                                                                        field.formConfig &&
                                                                        field
                                                                          .formConfig
                                                                          .prepend
                                                                          ? _c(
                                                                              "template",
                                                                              {
                                                                                slot: "prepend",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    field
                                                                                      .formConfig
                                                                                      .prepend
                                                                                  ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        field.formConfig &&
                                                                        field
                                                                          .formConfig
                                                                          .append
                                                                          ? _c(
                                                                              "template",
                                                                              {
                                                                                slot: "append",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    field
                                                                                      .formConfig
                                                                                      .append
                                                                                  ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      2
                                                                    )
                                                                  : field
                                                                      .formConfig
                                                                      .type ===
                                                                    "usd"
                                                                  ? _c(
                                                                      "el-input",
                                                                      {
                                                                        staticClass:
                                                                          "usd-input",
                                                                        attrs: {
                                                                          size: "mini",
                                                                          disabled:
                                                                            !!field.disabled,
                                                                        },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.onInput(
                                                                                $event,
                                                                                field
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row[
                                                                              field
                                                                                .fieldName
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                field.fieldName,
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row[field.fieldName]",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "usd-prefix",
                                                                            attrs:
                                                                              {
                                                                                slot: "prefix",
                                                                              },
                                                                            slot: "prefix",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "US $"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : [
                                                                      "date",
                                                                      "datetime",
                                                                    ].includes(
                                                                      field
                                                                        .formConfig
                                                                        .type
                                                                    )
                                                                  ? _c(
                                                                      "el-date-picker",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                        attrs: {
                                                                          size: "mini",
                                                                          type: field
                                                                            .formConfig
                                                                            .type,
                                                                          "value-format":
                                                                            "yyyy-MM-dd HH:mm:ss",
                                                                          disabled:
                                                                            !!field.disabled,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row[
                                                                              field
                                                                                .fieldName
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                field.fieldName,
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row[field.fieldName]",
                                                                        },
                                                                      }
                                                                    )
                                                                  : field
                                                                      .formConfig
                                                                      .type ===
                                                                    "time"
                                                                  ? _c(
                                                                      "el-time-picker",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                        attrs: {
                                                                          size: "mini",
                                                                          "value-format":
                                                                            "HH:mm:ss",
                                                                          disabled:
                                                                            !!field.disabled,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row[
                                                                              field
                                                                                .fieldName
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                field.fieldName,
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row[field.fieldName]",
                                                                        },
                                                                      }
                                                                    )
                                                                  : field
                                                                      .formConfig
                                                                      .type ===
                                                                    "select"
                                                                  ? _c(
                                                                      "el-select",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                        attrs: {
                                                                          filterable:
                                                                            "",
                                                                          size: "mini",
                                                                          disabled:
                                                                            !!field.disabled,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.onSelectChange(
                                                                                $event,
                                                                                field
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row[
                                                                              field
                                                                                .fieldName
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                field.fieldName,
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row[field.fieldName]",
                                                                        },
                                                                      },
                                                                      _vm._l(
                                                                        field
                                                                          .formConfig
                                                                          .options,
                                                                        function (
                                                                          option
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: option.value,
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    option.label,
                                                                                  value:
                                                                                    option.value,
                                                                                },
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                })
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }